var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-section',{staticClass:"lightgrey",attrs:{"id":"portal","space":"0"}},[_c('div',{staticClass:"col s12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"sort-by":_vm.startSortBy,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"material-icons float-left"},[_vm._v("add")]),_vm._v(" New User ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Full Name"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),(_vm.isNewForm)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"User Email"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1):_vm._e(),(_vm.isNewForm)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password"},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Phone #"},model:{value:(_vm.editedItem.phone),callback:function ($$v) {_vm.$set(_vm.editedItem, "phone", $$v)},expression:"editedItem.phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.usertypes,"item-text":"name","item-value":"id","label":"User type","outlined":""},model:{value:(_vm.editedItem.usertype),callback:function ($$v) {_vm.$set(_vm.editedItem, "usertype", $$v)},expression:"editedItem.usertype"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/vizion/users/' + item.id}},[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(!item.disabled)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.toggleUserDisabled(item.id, true)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.disabled)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.toggleUserDisabled(item.id, false)}}},[_vm._v(" mdi-plus ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }