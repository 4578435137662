<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        <v-data-table
          :headers="headers"
          :items="users"
          :sort-by="startSortBy"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Users</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="material-icons float-left">add</i> New User
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            label="Full Name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="isNewForm"
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            label="User Email"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="isNewForm"
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.password"
                            label="Password"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Phone #"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-select
                            v-model="editedItem.usertype"
                            :items="usertypes"
                            item-text="name"
                            item-value="id"
                            label="User type"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            <a :href="'/vizion/users/' + item.id">{{ item.id }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!item.disabled"
              small
              @click="toggleUserDisabled(item.id, true)"
            >
              mdi-delete
            </v-icon>
            <v-icon
            v-if="item.disabled"
              small
              @click="toggleUserDisabled(item.id, false)"
            >
              mdi-plus
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Users } from '@/database/users'
  import { System } from '@/database/system'
  import { FirebaseResults, Auth } from '@/database'
  import { Functions } from '@/database/config/firebase'

  export default {
    name: 'SectionMap',
    data: () => ({
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        active: true,
        name: '',
        email: '',
        password: '',
        phone: '',
        usertype: 'User',
        disabled: false,
      },
      defaultItem: {
        id: '',
        active: true,
        name: '',
        password: '',
        email: '',
        phone: '',
        usertype: 'User',
        disabled: false,
      },
      users: [],
      filteredUsers: [],
      usertypes: [],
      active: true,
      search: '',
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
      isNewForm () {
        return this.editedIndex === -1
      },
      formTitle () {
        return this.isNewForm ? 'New User' : 'Edit User'
      },
      startSortBy () {
        return 'name'
      },
      headers () {
        var header = [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Phone', value: 'phone' },
          { text: 'UserType', value: 'usertype' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      // dialogDelete (val) {
      //   val || this.closeDelete()
      // },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.fetchUsers()
        // Users.findAllLoanOfficers().onSnapshot(docs => {
        //   const results = FirebaseResults.map(docs)
        //   this.loanofficers = results.map(function(item){
        //     return { id: item.id, name: item.name }
        //   })
        // })

        // Companies.findAll().onSnapshot(docs => {
        //   const results = FirebaseResults.map(docs)
        //   this.companies = results.map(function(item){
        //     return { id: item.id, name: item.companyName }
        //   })
        // })
        System.findOne('usertypes').then(doc => {
          const results = doc.data().data
          this.usertypes = results.map(function(item){
            return { id: item.name, name: item.name }
          })
        })
      },
      runtest () {
        console.log(this.editedItem.companyId)
        // if (this.usertype === 'borrower'){
        //   this.usertype = 'loan officer'
        // } else {
        //   this.usertype = 'borrower'
        // }
      },
      formatNumber(val) {
        return val.toLocaleString(
          undefined, 
          { minimumFractionDigits: 2 },
        )
      },
      fetchUsers () {
          Users.findAll().onSnapshot(docs => {
            this.users = FirebaseResults.map(docs)
            // const unsubusers = systemUsers.subscribe(value => {
            //   users = value;
            // });
            
            // users = $systemUsers
            switch (this.user.data.usertype){
              case 'loan officer':
                Deals.findAllByLO(this.user.data.id)
                .get()
                .then(querySnapshot => {
                    const results = FirebaseResults.map(querySnapshot)
                    const deals = results // .map(doc) => ({ id: doc.id, name: doc.name }))
                    var usersToShow = deals.map(function(elt) { return elt.RequestedBy.User })
                    this.users = this.users.filter(function(elt) { return usersToShow.indexOf(elt.id) > -1 })
                    // now filter out the users to borrowers listed in the deals/RequestedBy/User
                    this.users = [...this.users]
                    this.filteredUsers = [...this.users]
                })
                break
              case 'processor':
                Deals.findAllByProcessor(this.user.data.id)
                .get()
                .then(querySnapshot => {
                    const results = FirebaseResults.map(querySnapshot)
                    const deals = results // .map(doc => ({ id: doc.id, name: doc.name }))
                    var usersToShow = deals.map(function(elt) { return elt.RequestedBy.User })
                    this.users = this.users.filter(function(elt) { return usersToShow.indexOf(elt.id) > -1 })
                    this.users = [...this.users]
                    this.filteredUsers = [...this.users]
                })
                break
              default: 
                this.filteredUsers = [...this.users]
                break
            }
          })
      },
      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      toggleUserDisabled (uid, disable) {
        const toggleUserDisabled = Functions.httpsCallable('toggleUserDisabled')
          toggleUserDisabled({ uid, disable })
            .then(() => {
              console.log('user ' + (disable ? 'disabled' : 'reactivated'))
              this.fetchUsers()
            })
            .catch(error => {
              console.log('error :', error)
            })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem)
          console.log(this.editedItem)
          Users.update(this.editedItem.id, this.editedItem).then(() => {
            console.log('user updated.')
          })
        } else {
          this.users.push(this.editedItem)
          const createNewUser = Functions.httpsCallable('createNewUser')
          createNewUser(this.editedItem)
            .then(() => {
              console.log('user added')
              this.fetchUsers()
            })
            .catch(error => {
              console.log('error :', error)
            })
        }
        this.close()
      },

      getCurrentStatus (statuses) {
        return statuses.slice().sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)[0].StatusName
      },
      filterUsers (searchTerm) {
        if (searchTerm.length < 2) {
          this.filteredUsers = [...this.users]
        } else {
          this.filteredUsers = filterResults(searchTerm, this.users, 'name').search()
        }
      },
    },
  }
</script>
